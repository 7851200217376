import { Table, Pagination, Tag, Modal, Flex, Button, Typography } from "antd";
import { useState, useEffect } from 'react';
import { fetchTagRewards, fetchUserVote } from "../../api/community";
import dayjs from "dayjs";
import RewardEditModalView from "./reward_edit_modal_view";
import UserInfoView from "./user_info_view";
import { capitalizeFirstLetter } from "../../utils/comm";

const RewardVotesModalView = ({ open, tag, tagId, voteDescription, onClose, toRewards }) => {
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return <UserInfoView user={text} showWallet={true} />
            },
        },
        {
            title: 'Selected',
            dataIndex: 'selected',
            key: 'selected',
            render: (_, record) => {
                let option = record.vote.options.find(item => item.id === record.voteOptionId);
                return <Tag>{option?.content}</Tag>
            },
        },
        {
            title: 'Reward Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if (rewardUsers.map(item => item.user.userId).includes(record.user.userId)) {
                    let reward = rewardUsers.find(item => item.user.userId === record.user.userId);
                    return <Typography.Text type="success"><b>SENT {`${reward.amount} ${reward.symbol} [${capitalizeFirstLetter(reward.chain)}]`}</b></Typography.Text>;
                }
                return '';
            },
        },
    ];

    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState();
    const [rewardUsers, setRewardUsers] = useState([]);
    const [openEditRewardModal, setOpenEditRewardModal] = useState(false);

    useEffect(() => {
        setSelectedRows(null);
        setRewardUsers([]);
        if (tag && open) {
            setPageNum(1);
            fetchData(1, pageSize);
            fetchUsers();
        }
    }, [tag, open]);

    const fetchData = (pNum, pSize) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            'tagId': tagId,
        };
        fetchUserVote(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list.map(item => {
                    return {...item, user: {...item.user, userId: item.userId}}
                }));
            }
        }).finally(() => setLoading(false))
    }

    const fetchUsers = () => {
        let params = {
            'page.disable': true,
            'tag': tag,
        };
        fetchTagRewards(params).then(res => {
            if (res.list) {
                setRewardUsers(res.list);
            }
        })
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const onTabChange = (pagination, filters, sorter, extra) => {
        let orderValue = 'Id';
        if (sorter.order) {
            if (sorter.field === 'agreeCount') {
                orderValue = 'LikeCount'
            } else if (sorter.field === 'commentCount') {
                orderValue = 'CommentCount';
            }

        }
        onSearchChange('sort', orderValue);
    }

    const editHandle = () => {
        setOpenEditRewardModal(true);
    }

    if (!tag) return <div />;

    return (
        <>
            <Modal
                maskClosable
                open={open}
                footer={null}
                width={800}
                onCancel={onClose}
                title={`[VOTE] ${voteDescription}`}>
                <div className='main-wrapper'>
                    <Flex justify="space-between" className="cnt-item" style={{ marginTop: 10 }}>
                        <Button disabled={!selectedRows || selectedRows.length <= 0} type="primary" onClick={editHandle}>Send Rewards</Button>
                        <Button onClick={toRewards}>Check Reward Records</Button>
                    </Flex>
                    <div className='cnt-item' style={{ marginTop: 10 }}>
                        <Table
                            scroll={{ x: true }}
                            loading={loading}
                            rowKey={(record) => record.id}
                            rowClassName={'table-row'}
                            size='small'
                            dataSource={tabData}
                            columns={tabCols}
                            pagination={false}
                            rowSelection={{
                                selectedRowKeys: (selectedRows ?? []).map(item => item.id),
                                getCheckboxProps: (record) => ({
                                    disabled: rewardUsers.map(item => item.user.userId).includes(record.user.userId),
                                }),
                                onChange: (keys, rows) => {
                                    setSelectedRows(rows);
                                }
                            }}
                            onChange={onTabChange} />
                    </div>
                    <div className='footer-part cnt-item'>
                        <Pagination
                            total={total}
                            showTotal={(total) => `total ${total}`}
                            current={pageNum}
                            pageSize={pageSize}
                            showSizeChanger={true}
                            pageSizeOptions={[10, 20, 30, 50]}
                            onChange={(page, size) => pageChange(page, size)}
                        />
                    </div>
                </div>
            </Modal>
            <RewardEditModalView
                open={openEditRewardModal}
                onClose={() => setOpenEditRewardModal(false)}
                onUpdate={() => {
                    setSelectedRows(null);
                    fetchData(pageNum, pageSize);
                    fetchUsers();
                }}
                users={(selectedRows ?? []).map(item => ({
                    userId: item.user.userId,
                    user: item.user,
                    postId: item.id,
                    txId: null,
                    tag: tag,
                    symbol: null,
                    amount: null,
                    autoWalletID: null,
                }))} />
        </>

    )
}

export default RewardVotesModalView;