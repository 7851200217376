import { useEffect, useState, useRef } from 'react'
import { blockUser, exportUsers, fetchUserSearch, fetchUserTags, updateUserVerification, userApplyTag } from '../../api/community';
import { Space, Avatar, Button, Table, Pagination, Flex, Input, Modal, Select, Tooltip, DatePicker, Tag, Popover } from 'antd';
import { CheckOutlined, ExclamationOutlined, ExportOutlined, SearchOutlined, TagsOutlined, UploadOutlined, UserOutlined, UserSwitchOutlined, WalletOutlined, WarningFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation } from 'react-router-dom';
import ApplicantModalView from '../../components/user/applicant_modal_view';
import UserInfoView from '../../components/community/user_info_view';
import { agentIsAndriod, agentIsIos, conversionUtcDate, exportCsv } from '../../utils/comm';
import AssetsBalanceView from '../../components/user/assets_balance_view';
import { Typography } from 'antd';
import UserTagPopoverView from '../../components/user/user_tag_popover_view';
import UserTagsUploadModalView from '../../components/user/user_tags_upload_modal_view';
import UserSuspiciousModalView from '../../components/user/user_suspicious_modal_view';
import DollarSrc from '../../assets/dollar.png';
import { MainLayoutUrl } from '../../router';
import { useNavigate } from 'react-router-dom';
import { countryTuples } from 'country-region-data';

export const VerificationTypeOptions = [
    { key: 0, label: 'General', value: 'NormalUser' },
    { key: 1, label: 'Verified User', value: 'VerifiedUser' },
    { key: 2, label: 'Verified Organisation', value: 'VerifiedOrganisation' },
    { key: 3, label: 'Paid User', value: 'PaidUser' },
    { key: 4, label: 'Paid Organisation', value: 'PaidOrganisation' },
]

const UsersScreen = () => {
    const location = useLocation();
    const navigateTo = useNavigate();

    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const tooltipRef = useRef(null);
    const [labels, setLabels] = useState([]);
    const [popOpen, setPopOpen] = useState(false);
    const [currUserId, setCurrUserId] = useState(null);
    const [suspicious, setSuspicious] = useState(null);
    const [suspiciousShow, setSuspiciousShow] = useState(false);
    const [selectedRows, setSelectedRows] = useState();
    const [lookModalShow, setLookModalShow] = useState(false);

    const countries = countryTuples.map(item => {
        return {
            label: item[0].split(',')[0],
            value: item[0],
            flag: item[1]
        }
    })

    const [searchParams, setSearchParams] = useState({
        country: '',
        keyword: '',
        username: '',
        isNew: '',
        start: '',
        end: ''
    });

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Registered',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'User',
            children: [
                {
                    title: 'Basic Info',
                    dataIndex: 'user',
                    key: 'user',
                    render: (_, record) => {
                        return (
                            <Space>
                                <UserInfoView user={record} showWallet />
                                {record.suspiciousDetail?.suspicious &&
                                    <WarningFilled style={{ fontSize: 20, color: 'red' }} onClick={() => warningHandle(record)} />}
                                {record.backupWallet && record.backupWalletTxID &&
                                    <img title='Register Reward' src={DollarSrc} style={{ width: 24, height: 'auto', cursor: 'pointer' }} onClick={() => toTxDetail(record)} />}
                            </Space>
                        );
                    }
                },
                {
                    title: 'Username/UserId',
                    dataIndex: 'username',
                    key: 'username',
                    render: (_, record) => {
                        return (
                            <div style={{ width: 240 }}>
                                <Typography.Text copyable><b>{record.username}</b></Typography.Text><br />
                                <Typography.Text copyable style={{ fontSize: 12 }}>{record.userId}</Typography.Text>
                            </div>
                        )
                    }
                },
                {
                    title: 'Country',
                    dataIndex: 'country',
                    key: 'country',
                    render: (_, record) => {
                        return (
                            <b>{record.country}</b>
                        )
                    }
                },
            ]
        },
        {
            title: 'Register Source',
            dataIndex: 'registerFrom',
            key: 'registerFrom',
            render: (text, record) => {
                if (!record.registerUserAgent) return '';
                if (text === 0) return <Tag color="#2db7f5">H5</Tag>
                else if (text === 1) return <Tag color="#87d068">Mobile</Tag>
                return '';
            }
        },
        {
            title: 'User Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (text, record) => {
                if (!text || text.length <= 0) return '-';
                return (
                    <Space size={1} wrap style={{ minWidth: 160, maxWidth: 240 }}>
                        {
                            Array.from(new Set(text.map(item => item.name))).map((item, index) => {
                                return (
                                    <Tag key={`${record.userId}_${index}`} color='green'>{item}</Tag>
                                )
                            })
                        }
                    </Space>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'blockTime',
            key: 'blockTime',
            render: (text, _) => {
                if (!text) return '-';
                let blockTime = new Date(conversionUtcDate(text)).getTime();
                if (blockTime > Date.now()) return <Tag color='error'>Blocked before <b>{conversionUtcDate(text, null, true)}</b></Tag>;
                return '-'
            }
        },
        {
            title: 'Browsed',
            dataIndex: 'browsedCount',
            key: 'browsedCount',
            render: (text, _) => {
                if (text === '0') return '-';
                return <b>{text}</b>;
            }
        },
        {
            title: 'Followers',
            dataIndex: 'fanCount',
            key: 'fanCount',
            render: (text, _) => {
                if (text === '0') return '-';
                return <b>{text}</b>;
            }
        },
        {
            title: 'Following',
            dataIndex: 'followCount',
            key: 'followCount',
            render: (text, _) => {
                if (text === '0') return '-';
                return <b>{text}</b>;
            }
        },
        {
            title: 'Get Liked',
            dataIndex: 'getLikeCount',
            key: 'getLikeCount',
            render: (text, _) => {
                if (text === '0') return '-';
                return <b>{text}</b>;
            }
        },
        {
            title: 'Last Login',
            children: [
                {
                    title: 'Mobile',
                    dataIndex: 'mobile',
                    key: 'mobile',
                    render: (_, record) => {
                        if (!record.extraUserInfo) return '-'
                        let { lastLoginAgent } = record.extraUserInfo;
                        if (agentIsAndriod(lastLoginAgent)) return <Avatar alt='Android' src='/images/google-play.png' size={20} />;
                        else if (agentIsIos(lastLoginAgent)) return <Avatar alt='Android' src='/images/app-store.png' size={20} />;
                        return '-'
                    }
                },
                {
                    title: 'Provider',
                    dataIndex: 'provider',
                    key: 'provider',
                    render: (_, record) => {
                        if (!record.extraUserInfo) return '-'
                        let { providerIds } = record.extraUserInfo
                        if (!providerIds || providerIds.length <= 0) return '-';
                        return (
                            <Space>
                                {
                                    Array.from(providerIds).map((item, index) => {
                                        let icon = '';
                                        if (item.includes('google')) {
                                            icon = '/images/google.png';
                                        } else if (item.includes('apple')) {
                                            icon = '/images/apple.png';
                                        } else if (item.includes('password')) {
                                            icon = '/images/mail.png';
                                        }
                                        if (icon) {
                                            return <Avatar key={index} src={icon} alt={item} size={20} />;
                                        }
                                        return <div key={index} />
                                    })
                                }
                            </Space>
                        )
                    }
                },
                {
                    title: 'IP',
                    dataIndex: 'ip',
                    key: 'ip',
                    render: (_, record) => {
                        if (!record.extraUserInfo) return '-'
                        let { lastLoginIp } = record.extraUserInfo
                        if (lastLoginIp) return (
                            <div style={{ width: 120 }}>
                                <Typography.Text copyable style={{ fontSize: 12, color: '#afafaf' }}>{lastLoginIp}</Typography.Text>
                            </div>
                        )
                        return '-'
                    },
                },
                {
                    title: 'DateTime',
                    dataIndex: 'openedAt',
                    key: 'openedAt',
                    render: (_, record) => {
                        if (!record.extraUserInfo) return '-'
                        let { openedAt } = record.extraUserInfo
                        if (openedAt) return (
                            <div style={{ fontSize: 12, color: '#afafaf', width: 120 }}>
                                {
                                    conversionUtcDate(openedAt, null, true)
                                }
                            </div>
                        )
                        return '-'
                    }
                },
            ]
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (_, record, index) => {
                let hasBlock = false;
                if (record.blockTime) {
                    let blockTime = new Date(conversionUtcDate(record.blockTime)).getTime();
                    hasBlock = blockTime > Date.now();
                }
                let disabled = record.newUser;
                return (
                    <Space>
                        {
                            (record.addressList && record.addressList.length > 0) && (
                                <Popover
                                    placement="topRight"
                                    title={<UserInfoView showWallet={false} user={record} />}
                                    content={
                                        <AssetsBalanceView user={record} />
                                    }
                                    trigger={'click'}>
                                    <Button disabled={disabled} shape='circle' icon={<WalletOutlined />} />
                                </Popover>
                            )
                        }
                        <Tooltip title='Verify' key={`verify_${index}`}>
                            <Button disabled={disabled} shape='circle' icon={<UserSwitchOutlined />} onClick={() => { editHandle(record) }} />
                        </Tooltip>

                        <Popover
                            placement="topRight"
                            title='User Labels'
                            open={popOpen && currUserId === record.userId}
                            content={
                                <UserTagPopoverView
                                    defaultTags={record.tags.map(item => item.name)}
                                    closed={popOpen}
                                    options={labels}
                                    confirmCallback={onUserLabelSubmit} />
                            }
                            trigger={'click'}
                            onOpenChange={onOpenChange}>
                            <Button shape='circle'
                                icon={<TagsOutlined />}
                                disabled={disabled}
                                onClick={() => {
                                    setCurrUserId(record.userId);
                                    setPopOpen(true);
                                }} />
                        </Popover>

                        {
                            hasBlock ?
                                <Tooltip title='Unblock' key={`unblock_${index}`}>
                                    <Button disabled={disabled} shape='circle' icon={<CheckOutlined />} onClick={() => { blockHandle([record]) }} />
                                </Tooltip>
                                :
                                <Tooltip title='Block' key={`block_${index}`}>
                                    <Button disabled={disabled} danger shape='circle' icon={<ExclamationOutlined />} onClick={() => { blockHandle([record]) }} />
                                </Tooltip>
                        }
                    </Space>
                )
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        getLabels();
        getUsers(pageNum, pageSize, searchParams);
    }, [location])

    const onOpenChange = (value) => {
        setPopOpen(value);
    }

    const getLabels = () => {
        fetchUserTags({ 'page.disable': true }).then(res => {
            if (res.list) {
                let tmp = Array.from(res.list).map(item => item.children).reduce((x, y) => Array.from(x).concat(y));
                setLabels(tmp.map(item => ({ label: item.name, value: item.name, key: item.id })));
            }
        });
    }

    const getUsers = useDebouncedCallback(
        (pNum, pSize, search) => {
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                ...search,
            }
            setLoading(true);
            setSelectedRows([]);
            fetchUserSearch(params).then(res => {
                if (res.list) {
                    setTabData(res.list);
                }
                if (res.page) {
                    setTotal(res.page.total);
                }
            }).finally(() => setLoading(false))
        },
        300
    )

    const getVerification = (type, description) => {
        if (type === 'VerifiedUser' || type === 'VerifiedOrganisation') {
            if (description) {
                return (
                    <Tooltip ref={tooltipRef} title={description}>
                        <div>
                            <Avatar size={16} src={type === 'VerifiedUser' ? '/images/personal.png' : '/images/company.png'} />
                        </div>
                    </Tooltip>
                )
            }
            return <Avatar size={16} src={type === 'VerifiedUser' ? '/images/personal.png' : '/images/company.png'} />
        }
        return <div />
    }

    const onUserLabelSubmit = (tags) => {
        setLoading(true);
        userApplyTag({ userId: currUserId, list: Array.from(tags).map(item => ({ name: item })) }).then(res => {
            if (res) {
                setLoading(false);
                setPopOpen(false);
                setCurrUserId(null);
                getUsers(pageNum, pageSize, searchParams);
            }
        });
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getUsers(page, size, searchParams);
    }

    const editHandle = (record) => {
        let verify = (record.certifications ?? [])[0]?.type;
        let description = (record.certifications ?? [])[0]?.description;
        let src = record.picture ?? record.avatar;
        let modal = Modal.confirm({
            width: 600,
            closable: false,
            title: 'User Verification',
            content: (
                <div>
                    <div style={{ marginTop: 32 }} />
                    <Space size={4} style={{ justifyContent: 'center', width: '100%' }}>
                        {
                            src ?
                                <Avatar size={48} src={src} />
                                :
                                <Avatar size={48} icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
                        }
                        <div>
                            <Flex align='center'>
                                <b style={{ marginRight: 4 }}>{record.name ? record.name : record.email}</b>
                                {getVerification(verify, description)}
                            </Flex>
                            <div style={{ color: '#afafaf', fontSize: 12 }}>{record.name ? record.email : ''}</div>
                        </div>
                    </Space>
                    <div style={{ marginTop: 32 }} />
                    <Flex align='center'>
                        <div style={{ width: 120, textAlign: 'right', marginRight: 10 }}>User Type:</div>
                        <Select
                            style={{ width: 300 }}
                            options={VerificationTypeOptions}
                            defaultValue={verify}
                            placeholder='select user type'
                            onChange={value => {
                                verify = value;
                                modal.update({
                                    okButtonProps: {
                                        disabled: !verify?.trim(),
                                    }
                                })
                            }}
                            allowClear
                        />
                    </Flex>
                    <div style={{ marginTop: 12 }} />
                    <Flex align='center'>
                        <div style={{ width: 120, textAlign: 'right', marginRight: 10 }}>Description:</div>
                        <Input style={{ width: 300 }} defaultValue={description} placeholder="verification description" onChange={evt => {
                            description = evt.target.value;
                        }} allowClear />
                    </Flex>
                    <div style={{ marginTop: 20 }} />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !verify?.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { userId: record.userId, list: [{ type: verify === 'NormalUser' ? '' : verify, description: description }] };
                await updateUserVerification(params);
                getUsers(pageNum, pageSize, searchParams);
            }
        })
    }

    const blockHandle = (users) => {
        let blockTime = '';
        let modal = Modal.confirm({
            width: 600,
            closable: false,
            title: 'Block User',
            content: (
                <div>
                    <div style={{ marginTop: 32 }} />
                    <Space style={{ justifyContent: 'center', width: '100%' }} wrap>
                        {
                            users.map((item) => {
                                return <UserInfoView key={item.userId} user={item} showWallet={false} />
                            })
                        }
                    </Space>
                    <div style={{ marginTop: 32 }} />
                    <Flex align='center'>
                        <div style={{ width: 120, textAlign: 'right', marginRight: 10 }}> Set Block Time:</div>
                        <DatePicker
                            style={{ width: 300 }}
                            variant="filled"
                            showTime
                            format={(value) => `${value ? conversionUtcDate(value) : ''}`}
                            placeholder="Choose block time"
                            onChange={(_, date) => {
                                blockTime = date;
                                modal.update({
                                    okButtonProps: {
                                        disabled: !blockTime?.trim(),
                                    }
                                })
                            }}
                        />
                    </Flex>
                    <div style={{ marginTop: 20 }} />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !blockTime?.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { userId: users.map(item => item.userId), blockTime: conversionUtcDate(blockTime, 'UTC') };
                await blockUser(params);
                getUsers(pageNum, pageSize, searchParams);
            }
        })
    }

    const onSearchChange = (key, value) => {
        let newParams = { ...searchParams };
        if (key === 'dates') {
            if (value.length == 2) {
                newParams.start = `${value[0]}T00:00:00Z`;
                newParams.end = `${value[1]}T23:59:59Z`;
            } else {
                newParams.start = '';
                newParams.end = '';
            }
        } else {
            newParams[key] = value;
        }
        setSearchParams(newParams);
        setPageNum(1);
        getUsers(1, pageSize, newParams);
    }

    const warningHandle = (record) => {
        setSuspicious(record);
        setSuspiciousShow(true);
    }

    const toTxDetail = (record) => {
        navigateTo(`${MainLayoutUrl.crypto.transactions.index.path}?tx=${record.backupWalletTxID}`)
    }

    const exportHandle = () => {
        setLoading(true);
        exportUsers({ ...searchParams, size: total }).then(res => {
            if (res) {
                exportCsv(res, '用户数据');
            }
        }).finally(() => setLoading(false))
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Space wrap style={{ marginRight: 40 }}>
                        <DatePicker.RangePicker
                            onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
                        <Input
                            style={{ width: 200 }}
                            placeholder="keyword..."
                            allowClear
                            onChange={evt => onSearchChange('keyword', evt.target.value)} />
                        <Input
                            style={{ width: 200 }}
                            placeholder="user name..."
                            allowClear
                            onChange={evt => onSearchChange('username', evt.target.value)} />
                        <Select
                            style={{ width: 120 }}
                            options={[
                                { label: 'New User', value: true, key: 1 },
                                { label: 'Old User', value: false, key: 2 }
                            ]}
                            placeholder='Is New'
                            onChange={value => onSearchChange('isNew', value)}
                            allowClear
                        />
                        <Select
                            style={{ width: 220 }}
                            placeholder="country/city select..."
                            options={countries}
                            optionRender={(option) => {
                                let flag = `http://purecatamphetamine.github.io/country-flag-icons/3x2/${option.data.flag}.svg`;
                                return (
                                    <Space size={[4, 0]}>
                                        <Avatar src={flag} size={16} />
                                        <Typography.Text style={{ fontSize: 12 }}>
                                            <b>{option.data.label}</b>
                                        </Typography.Text>
                                    </Space>
                                )
                            }}
                            onChange={val => onSearchChange('country', val)}
                            allowClear
                            showSearch />
                        <Button onClick={() => setLookModalShow(true)}>Looker Data</Button>
                    </Space>

                    <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <Button onClick={() => setOpen(true)} >Verification Applicants</Button>
                        <Button disabled={selectedRows && selectedRows.length > 0} type='primary' onClick={() => setUploadModalOpen(true)} icon={<UploadOutlined />}>Upload Tags</Button>
                        <Button type='primary' onClick={() => exportHandle()} icon={<ExportOutlined />}>Export Users</Button>
                        {
                            (selectedRows && selectedRows.length > 0) &&
                            <Button onClick={() => blockHandle(selectedRows)} type='primary' danger icon={<ExclamationOutlined />}>Block Users</Button>
                        }
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    bordered
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.userId}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false}
                    rowSelection={{
                        selectedRowKeys: (selectedRows ?? []).map(item => item.userId),
                        onChange: (keys, rows) => {
                            setSelectedRows(rows);
                        }
                    }} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 30, 50, 100, 200]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
            <ApplicantModalView open={open} onClose={() => setOpen(false)} />

            <UserTagsUploadModalView
                options={labels}
                open={uploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
                onUpdate={() => {
                    getUsers(pageNum, pageSize, searchParams);
                }} />

            <UserSuspiciousModalView
                open={suspiciousShow}
                user={suspicious}
                onClose={() => {
                    setSuspiciousShow(false)
                }} />

            <Modal open={lookModalShow} width={1080} title='Looker Studio Data' onCancel={() => setLookModalShow(false)} footer={null}>
                <div style={{ height: '70vh' }}>
                    <iframe width="100%" src="https://lookerstudio.google.com/embed/reporting/fbbf3815-daad-4600-9f1b-3d7c63748945/page/p_mempfcj5nd" frameborder="0" style={{ border: 0, height: '100%', width: '100%' }} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
                </div>
            </Modal>
        </div>
    )
}

export default UsersScreen;