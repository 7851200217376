import { CheckOutlined } from "@ant-design/icons";
import { Space, Select, Button, Spin, Typography } from "antd"
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { fetchAllTags } from "../../api/community";
import { conversionUtcDate } from "../../utils/comm";

const PostAdminTagPopoverView = ({ defaultTags, confirmCallback, closed }) => {
    const [tags, setTags] = useState([]);
    const [topics, setTopics] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [needSave, setNeedSave] = useState(false);

    useEffect(() => {
        setTags(defaultTags ? JSON.parse(JSON.stringify(defaultTags)) : []);
    }, [defaultTags])

    useEffect(() => {
        if (tags.sort().join(',') !== defaultTags.sort().join(',')) {
            setNeedSave(true);
        } else {
            setNeedSave(false);
        }
    }, [tags])

    useEffect(() => {
        if(!closed) {
            setTags([]);
        }
    }, [closed])

    const getTopicTags = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'name': keyword,
                'level': 1,
                'isTopicTag': null,
                'status': 0,
                'isTickerTag': false
            }
            setSearchLoading(true);
            fetchAllTags(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        createdAt: conversionUtcDate(item.createdAt, null, true),
                        label: item.name,
                        value: item.name,
                    }));
                    setTopics(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const onSelectChange = (val) => {
        setTags(val);
    }

    const toSubmit = () => {
        confirmCallback(tags);
    }

    return (
        <Space size={4}>
            <Select
                mode='multiple'
                style={{ width: 400 }}
                size='large'
                placeholder='search tags'
                value={tags}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={(value) => getTopicTags(value)}
                notFoundContent={searchLoading ? <Spin /> : null}
                options={topics}
                optionRender={(option) => {
                    return (
                        <Space>
                            <div style={{ flex: 1 }}>
                                <b style={{ wordBreak: 'break-word' }}>{option.data.label}</b>
                                <div>
                                    <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                        {option.data.createdAt}
                                    </Typography.Text>
                                </div>
                            </div>
                        </Space>
                    )
                }}
                onChange={onSelectChange}
                allowClear
                showSearch
            />
            {
                needSave ?
                    <Button type='primary' shape='circle' icon={<CheckOutlined />} size='large' onClick={toSubmit} />
                    :
                    null
            }
        </Space>
    )
}

export default PostAdminTagPopoverView;