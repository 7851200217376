import { Button, Space, Table, Pagination, Flex, Input, Typography, Tag } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { fetchBountyTags } from "../../api/community";
import RewardPostsModalView from "../../components/community/reward_posts_modal_view";
import RewardUsersModalView from "../../components/community/reward_users_modal_view";
import { conversionUtcDate } from "../../utils/comm";
import { useLocation } from "react-router-dom";
import BountyEditDrawer from "../../components/community/bounty_edit_drawer";
import RewardVotesModalView from "../../components/community/reward_votes_modal_view";

const TopicScreen = () => {
    const location = useLocation();
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1)
        },
        {
            title: 'Bounty Topic ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, _) => {
                return (
                    <Typography.Text style={{ color: '#999', fontSize: 12 }} copyable>{text}</Typography.Text>
                );
            },
        },
        {
            title: 'Bounty Topic Title',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <>
                        <Typography.Text mark>{`#${text}`}</Typography.Text>
                        {
                            record.vote && Array.from(record.vote.options ?? []).length > 0 && (
                                <Tag color="#55acee" style={{ fontStyle: 'italic', marginLeft: 4, padding: '0 2px', fontSize: 11, display: 'inline-block', lineHeight: 1 }}><b>Vote</b></Tag>
                            )
                        }
                    </>
                );
            },
        },
        {
            title: 'Created',
            dataIndex: 'reward',
            key: 'reward',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text.createdAt).format('YYYY-MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'End At',
            dataIndex: 'reward',
            key: 'reward',
            render: (text, _) => {
                if (!text.endAt) return '-';
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{conversionUtcDate(text.endAt)}</label>
            },
        },
        {
            title: 'Rewards Amount',
            dataIndex: 'reward',
            key: 'reward',
            render: (text, _) => {
                return <Typography.Text type="success"><b>{text.count} &nbsp;{text.symbol}</b></Typography.Text>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size="small">
                        {
                            record.vote && Array.from(record.vote.options ?? []).length > 0 && (
                                <Button type='link' onClick={() => { relatedVotesHandle(record) }}>Related Votes</Button>
                            )
                        }
                        <Button type='link' onClick={() => { relatedPostsHandle(record) }}>Related Posts</Button>
                        <Button type='link' onClick={() => { rewardsRecordHandle(record) }}>Reward Records</Button>
                    </Button.Group>
                )
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        tag: '',
    });
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openRelatedPosts, setOpenRelatedPosts] = useState(false);
    const [openRelatedVotes, setOpenRelatedVotes] = useState(false);
    const [openRewardRecords, setOpenRewardRecords] = useState(false);
    const [currTag, setCurrTag] = useState();
    const [currTagId, setCurrTagId] = useState();
    const [voteDesc, setVoteDesc] = useState();
    const [openBountyEdit, setOpenBountyEdit] = useState(false);

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);
    }, [location]);

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchBountyTags(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            newParams[tag] = value;
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const relatedVotesHandle = (item) => {
        setCurrTag(item.name);
        setCurrTagId(item.id);
        setVoteDesc(item.vote.description);
        setOpenRelatedVotes(true);
    }

    const relatedPostsHandle = (item) => {
        setCurrTag(item.name);
        setOpenRelatedPosts(true);
    }

    const rewardsRecordHandle = (item) => {
        setCurrTag(item.name);
        setOpenRewardRecords(true);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify="space-between">
                    <div />
                    <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('tag', evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false}
                    expandable={{ rowExpandable: (record) => false, showExpandColumn: false }} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
            <RewardPostsModalView
                open={openRelatedPosts}
                tag={currTag}
                onClose={() => setOpenRelatedPosts(false)}
                toRewards={() => {
                    setOpenRelatedPosts(false);
                    setOpenRelatedVotes(false);
                    setOpenRewardRecords(true);
                }} />
            <RewardVotesModalView
                open={openRelatedVotes}
                tag={currTag}
                tagId={currTagId}
                voteDescription={voteDesc}
                onClose={() => setOpenRelatedVotes(false)}
                toRewards={() => {
                    setOpenRelatedPosts(false);
                    setOpenRelatedVotes(false);
                    setOpenRewardRecords(true);
                }} />
            <RewardUsersModalView
                open={openRewardRecords}
                tag={currTag}
                onClose={() => setOpenRewardRecords(false)} />
            <BountyEditDrawer
                open={openBountyEdit}
                defaultBounty={currTag}
                onClose={() => setOpenBountyEdit(false)}
                onUpdate={() => {
                    fetchData(pageNum, pageSize, searchParams);
                }} />
        </div>
    )
}

export default TopicScreen;