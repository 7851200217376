import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { updateCrawledPost } from "../../api/community";
import { Descriptions, Button, message} from 'antd'
import RichTextEditor from "../../components/editor";
import { MainLayoutUrl } from "../../router";
import '../../style/community.css';

const CrawledDetailScreen = () => {
    const navigateTo = useNavigate();
    const location = useLocation();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const defaultData = location.state && location.state.detail ? JSON.parse(location.state.detail) : { };

    useEffect(() => {
        setData({
            ...defaultData,
            events: Array.from(defaultData.events ?? []).map(item => item.eventType).join(','),
        });
    }, [])

    const onFormChange = (tag, value) => {
        let newData = { ...data };
        newData[tag] = value;
        setData(newData);
    }

    const toSubmit = () => {
        setLoading(true);
        let params = { ...data};
        updateCrawledPost(params).then(res => {
            if (res) {
                message.success('updated!');
                onClose();
            }
        }).catch((error) => {
            message.error(String(error));
        }).finally(() => {
            setLoading(false);
        });
    }

    const onClose = () => {
        navigateTo(MainLayoutUrl.community.crawled.index.path);
    }

    return (
        <div style={{ padding: '20px 40px' }}>
            <div className="title">Edit Crawled Content</div>
            <div style={{ height: 30 }} />
            <Descriptions size='large' bordered column={2} layout="vertical" labelStyle={{ width: 250, textAlign: 'center' }}>
                <Descriptions.Item label='Content' span={2}>
                    <RichTextEditor
                        defaultHeight={300}
                        defaultContent={data.content}
                        contentField='content'
                        needEditTags={false}
                        onUpdate={(field, result, tagName) => onFormChange(field, result)} />
                </Descriptions.Item>
            </Descriptions>
            <div className="btn-area">
                <Button loading={loading} type="primary" style={{ width: 200, height: 44 }} onClick={toSubmit}>Submit</Button>
                <div style={{ width: 24 }} />
                <Button loading={loading} block style={{ width: 200, height: 44 }} onClick={onClose}>Cancel</Button>
            </div>
        </div>
    )
}

export default CrawledDetailScreen;