import { useEffect, useState } from 'react'
import { exportUsersQuiz, fetchProfilingQuiz } from '../../api/community';
import { Space, Button, Table, Pagination, Flex, Select } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import UserInfoView from '../../components/community/user_info_view';
import dayjs from 'dayjs';
import { Typography } from 'antd';
import { exportCsv } from '../../utils/comm';

const ProfilingQuizScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState();

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
            fixed: 'left',
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text, _) => {
                if (!text) return ''
                return <UserInfoView user={text} showWallet={false} />
            },
            fixed: 'left',
        },
        {
            title: 'Have you ever invested in financial products?',
            dataIndex: 'answer1',
            key: 'answer1',
            render: (_, record) => {
                return <div style={{ fontSize: 11, width: 160 }}>{record.answer[0].val}</div>;
            },
        },
        {
            title: 'In which types of assets do you typically invest?',
            dataIndex: 'answer2',
            key: 'answer2',
            render: (_, record) => {
                let quiz = record.answer.filter(item => item.name === 'In which types of assets do you typically invest? (Select all that apply)')[0];
                if (quiz) {
                    let ans = quiz.val;
                    if (Array.isArray(ans)) {
                        return <Space size={4} wrap style={{ width: 160 }}>
                            {ans.map((item, index) => <Typography.Text style={{ fontSize: 11 }} key={index} mark>{item}</Typography.Text>)}
                        </Space>
                    }
                    return ans;
                }
                return '-'
            },
        },
        {
            title: 'What type of content interests you?',
            dataIndex: 'answer3',
            key: 'answer3',
            render: (_, record) => {
                let quiz = record.answer.filter(item => item.name === 'What type of content interests you? (Select all that apply)')[0];
                if (quiz) {
                    let ans = quiz.val;
                    if (Array.isArray(ans)) {
                        return <Space size={[4, 0]} wrap style={{ width: 160 }}>
                            {ans.map((item, index) => <Typography.Text style={{ fontSize: 11 }} key={index} mark>{item}</Typography.Text>)}
                        </Space>
                    }
                    return ans;
                }
                return '-'
            },
        },
        {
            title: 'Which platforms do you use to access market information?',
            dataIndex: 'answer4',
            key: 'answer4',
            render: (_, record) => {
                let quiz = record.answer.filter(item => item.name === 'Which platforms do you use to access market information? (Select all that apply)')[0];
                if (quiz) {
                    let ans = quiz.val;
                    if (Array.isArray(ans)) {
                        return <Space size={[4, 0]} wrap style={{ width: 160 }}>
                            {ans.map((item, index) => <Typography.Text style={{ fontSize: 11 }} key={index} mark>{item}</Typography.Text>)}
                        </Space>
                    }
                    return ans;
                }
                return '-'
            },
        },
        {
            title: 'What type of content format do you prefer?',
            dataIndex: 'answer5',
            key: 'answer5',
            render: (_, record) => {
                let quiz = record.answer.filter(item => item.name === 'What type of content format do you prefer? (Select all that apply)')[0];
                if (quiz) {
                    let ans = quiz.val;
                    if (Array.isArray(ans)) {
                        return <Space size={[4, 0]} wrap style={{ width: 160 }}>
                            {ans.map((item, index) => <Typography.Text style={{ fontSize: 11 }} key={index} mark>{item}</Typography.Text>)}
                        </Space>
                    }
                    return ans;
                }
                return '-'
            },
        },
        {
            title: 'What financial topics would you like to learn more about?',
            dataIndex: 'answer7',
            key: 'answer7',
            render: (_, record) => {
                let quiz = record.answer.filter(item => item.name === 'What financial topics would you like to learn more about? (Select all that apply)')[0];
                if (quiz) {
                    let ans = quiz.val;
                    if (Array.isArray(ans)) {
                        return <Space size={[4, 0]} wrap style={{ width: 160 }}>
                            {ans.map((item, index) => <Typography.Text style={{ fontSize: 11 }} key={index} mark>{item}</Typography.Text>)}
                        </Space>
                    }
                    return ans;
                }
                return '-'
            },
        },
        {
            title: 'What type of content would you like to receive?',
            dataIndex: 'answer8',
            key: 'answer8',
            render: (_, record) => {
                let quiz = record.answer.filter(item => item.name === 'What type of content would you like to receive? (Select all that apply)')[0];
                if (quiz) {
                    let ans = quiz.val;
                    if (Array.isArray(ans)) {
                        return <Space size={[4, 0]} wrap style={{ width: 160 }}>
                            {ans.map((item, index) => <Typography.Text style={{ fontSize: 11 }} key={index} mark>{item}</Typography.Text>)}
                        </Space>
                    }
                    return ans;
                }
                return '-'
            },
        },
        {
            title: 'What type of learning content format do you prefer?',
            dataIndex: 'answer9',
            key: 'answer9',
            render: (_, record) => {
                let quiz = record.answer.filter(item => item.name === 'What type of learning content format do you prefer? (Select all that apply)')[0];
                if (quiz) {
                    let ans = quiz.val;
                    if (Array.isArray(ans)) {
                        return <Space size={[4, 0]} wrap style={{ width: 160 }}>
                            {ans.map((item, index) => <Typography.Text style={{ fontSize: 11 }} key={index} mark>{item}</Typography.Text>)}
                        </Space>
                    }
                    return ans;
                }
                return '-'
            },
        },
        {
            title: 'Are you interested in connecting with other investors to discuss insights and experiences?',
            dataIndex: 'answer10',
            key: 'answer10',
            render: (_, record) => {
                let quiz = record.answer.filter(item => item.name === 'Are you interested in connecting with other investors to discuss insights and experiences?')[0];
                if (quiz) {
                    let ans = quiz.val;
                    return <div style={{ fontSize: 11, width: 200 }}>{ans}</div>;
                }
                return '-'
            },
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                if (!text) return ''
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
    ];

    useEffect(() => {
        getQuizs(pageNum, pageSize, keyword);
    }, [])

    const getQuizs = useDebouncedCallback(
        (pNum, pSize, search) => {
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                'questionName': 'Have you ever invested in financial products?',
                'questionVal': search
            }
            setLoading(true);
            fetchProfilingQuiz(params).then(res => {
                if (res.list) {
                    setTabData(res.list.map(item => {
                        return { ...item, answer: JSON.parse(item.answer) }
                    }));
                }
                if (res.page) {
                    setTotal(res.page.total);
                }
            }).finally(() => setLoading(false))
        },
        300)

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getQuizs(page, size, keyword);
    }

    const onSearchChange = (value) => {
        setKeyword(value);
        setPageNum(1);
        getQuizs(1, pageSize, value);
    }

    const exportHandle = () => {
        let params = {
            'size': total,
            'questionName': 'Have you ever invested in financial products?',
            'questionVal': keyword
        }
        setLoading(true);
        exportUsersQuiz(params).then(res => {
            if (res) {
                exportCsv(res, '问卷调查数据');
            }
        }).finally(() => setLoading(false))
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>

                <Flex justify='space-between'>
                    <Space>
                        <Button type='primary' onClick={exportHandle} icon={<ExportOutlined />}>Export</Button>
                    </Space>
                    <Select
                        style={{ width: 420 }}
                        options={[
                            { label: 'Yes, I have investment experience', value: 'Yes, I have investment experience', key: 1 },
                            { label: 'No, I am a beginner', value: 'No, I am a beginner', key: 2 }
                        ]}
                        placeholder='Have you ever invested in financial products?'
                        onChange={value => onSearchChange(value)}
                        allowClear
                    />
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default ProfilingQuizScreen;