import { Modal, Descriptions, Spin, Typography, Tag, Tooltip } from "antd"
import { useEffect, useState } from "react";
import { fetchTxDetail } from "../../api/crypto";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter, conversionUtcDate } from "../../utils/comm";

const TransactionDetailModalView = ({ tx, open, onClose }) => {
    const [txDetail, setTxDetail] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tx) {
            setLoading(true);
            fetchTxDetail({ id: tx.id, session: tx.session }).then(res => {
                setTxDetail(res);
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [tx])

    const gasFeeDesc = () => {
        if (txDetail) {
            let suffix = ''
            let chain = String(txDetail.chain).toLowerCase();
            if (chain.includes('ethereum')) {
                suffix = 'ETH'
            } else if (chain.includes('bitcoin')) {
                suffix = 'BTC'
            } else if (chain.includes('polygon')) {
                suffix = 'POL'
            }
            if (tx.gas && suffix) {
                return `${tx.gas} ${suffix}`;
            }
        }
        return '-';
    }

    const getStatusView = () => {
        if (!txDetail) return '-'
        if (txDetail.status === 0) {
            return <Tag color='processing'>Pending</Tag>
        } else if (txDetail.status === 1) {
            return <Tag color='success'>Mined</Tag>
        } else {
            return <Tooltip title={<div style={{ fontSize: 12, maxWidth: 240 }}>{txDetail.remark}</div>}>
                <Tag color='error'>{txDetail.status === 2 ? 'Failed' : 'Dropped'} <InfoCircleOutlined /></Tag>
            </Tooltip>
        }
    }

    return (
        <Modal title='Transaction Detail' open={open} onCancel={onClose} footer={null} width={720}>
            {
                loading ?
                    <Spin indicator={<LoadingOutlined />} />
                    :
                    (
                        txDetail && (
                            <Descriptions bordered column={1} labelStyle={{ textAlign: 'right', width: 160 }} style={{ marginTop: 24 }}>
                                <Descriptions.Item label='Chain' span={1}>
                                    <Typography.Text>{capitalizeFirstLetter(txDetail.chain)}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label='TxId' span={1}>
                                    {
                                        txDetail.txId ?
                                            <Typography.Text copyable>{txDetail.txId}</Typography.Text>
                                            :
                                            '-'
                                    }

                                </Descriptions.Item>
                                <Descriptions.Item label='Status' span={1}>
                                    {getStatusView()}
                                </Descriptions.Item>
                                <Descriptions.Item label='Nonce' span={1}>
                                    <Typography.Text>{txDetail.nonce}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label='Amount' span={1}>
                                    <b>{`${txDetail.amount} ${txDetail.symbol}`}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label='Gas Fee' span={1}>
                                    <b>{gasFeeDesc()}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label='From' span={1}>
                                    <Typography.Text copyable>{txDetail.fromAddress}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label='To' span={1}>
                                    <Typography.Text copyable>{txDetail.toAddress}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label='CreatedAt' span={1}>
                                    <Typography.Text style={{ fontSize: 12, color: '#afafaf' }}>{conversionUtcDate(txDetail.createdAt)}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label='Last Updated' span={1}>
                                    <Typography.Text style={{ fontSize: 12, color: '#afafaf' }}>{conversionUtcDate(txDetail.updatedAt)}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label='Wallet Id' span={1}>
                                    <Typography.Text copyable>{txDetail.walletId}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label='Session' span={1}>
                                    <Typography.Text copyable>{txDetail.session}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label='Remark' span={1}>
                                    <Typography.Text style={{ fontSize: 12, color: '#afafaf' }}>{txDetail.remark ? txDetail.remark : '-'}</Typography.Text>
                                </Descriptions.Item>
                            </Descriptions>
                        )
                    )
            }
        </Modal>
    )
}

export default TransactionDetailModalView;