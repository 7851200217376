import React, { useEffect, useMemo, useState } from "react";
import {
    Route,
    HashRouter,
    Routes,
    useLocation,
    Navigate,
    useOutlet,
} from "react-router-dom";
import {
    AppstoreOutlined,
    GlobalOutlined,
    CarryOutOutlined,
    BookOutlined,
    InstagramOutlined,
    HomeOutlined,
    CommentOutlined,
    UserSwitchOutlined,
    QuestionCircleOutlined,
    HighlightOutlined,
    TeamOutlined,
    UsergroupAddOutlined,
    TagsOutlined,
    DashboardOutlined,
    ControlOutlined,
    IssuesCloseOutlined,
    MessageOutlined,
    StarFilled,
    ProductOutlined,
    TrademarkCircleOutlined,
    SolutionOutlined,
    SnippetsOutlined,
    FlagOutlined,
    ReconciliationOutlined,
    HolderOutlined,
    ProfileOutlined,
    ReadOutlined,
    FileTextOutlined,
    WalletOutlined,
    DollarOutlined,
    BarsOutlined,
    VideoCameraOutlined,
    TagOutlined,
    ClusterOutlined,
    SettingOutlined,
    VideoCameraAddOutlined,
    LoadingOutlined,
    IdcardOutlined,
    LineChartOutlined,
    BugOutlined,
    QuestionOutlined,
    AuditOutlined,
} from "@ant-design/icons";
import LoginPanel from "../screens/login";
import MainLayout from "../layout/mainlayout";
import { getCookie } from "../utils/cookie";
import BannerScreen from "../screens/banner";
import BannerDetailScreen from "../screens/banner/detail";
import DiscoverScreen from "../screens/discover";
import DiscoverDetailScreen from "../screens/discover/detail";
import DailyScreen from "../screens/daily";
import CourseScreen from "../screens/course";
import CourseDetailScreen from "../screens/course/detail";
import NewsScreen from "../screens/news";
import NewsDetailScreen from "../screens/news/detail";
import DailyDetailScreen from "../screens/daily/detail";
import CommunityDetailScreen from "../screens/community/detail";
import DailyQaScreen from "../screens/daily/qa_index";
import UsersScreen from "../screens/users";
import UserRecommendScreen from "../screens/users/recommend_user";
import TopicScreen from "../screens/community/bounty_topic";
import KeepAlive from "keepalive-for-react";
import DashboardScreen from "../screens/dashboard";
import SystemSettingScreen from "../screens/system";
import ReportScreen from "../screens/community/report";
import CommentsScreen from "../screens/community/comment";
import PointProductsScreen from "../screens/points/products";
import PointBalanceScreen from "../screens/points/balances";
import UserLabelScreen from "../screens/users/labels";
import HomeArticleScreen from "../screens/home/hot_article";
import HomeTopicScreen from "../screens/home/hot_topic";
import PointRecordsScreen from "../screens/points/history";
import AllTagsScreen from "../screens/community/topic_tags";
import TagDetailScreen from "../screens/community/tagdetail";
import ContentTagsScreen from "../screens/community/content_tags";
import UserPostsScreen from "../screens/community/user_posts";
import UserArticlesScreen from "../screens/community/user_articles";
import AdminPostsScreen from "../screens/community/admin_posts";
import AdminArticlesScreen from "../screens/community/admin_articles";
import WalletScreen from "../screens/crypto/wallet";
import TransactionsScreen from "../screens/crypto/transactions";
import HotBountyScreen from "../screens/discover/hot_bounty";
import AdminsScreen from "../screens/system/admins";
import RolesScreen from "../screens/system/roles";
import RoleDetailScreen from "../screens/system/role_detail";
import DiscoverHotPostsScreen from "../screens/discover/hot_post";
import DiscoverHotVideosScreen from "../screens/discover/hot_video";
import { Spin, Flex } from "antd";
import { fetchAdminInfo } from "../api/admin";
import NotAccessScreen from "../screens/login/not_access";
import VirtualUserScreen from "../screens/users/virtual_user";
import PointDataScreen from "../screens/points/datas";
import PointSettingScreen from "../screens/points/setting";
import CrawledPostsScreen from "../screens/community/crawled_posts";
import CrawledDetailScreen from "../screens/community/crawled_detail";
import PendingPostsScreen from "../screens/community/pending_posts";
import PendingArticlesScreen from "../screens/community/pending_articles";
import PendingCommentsScreen from "../screens/community/pending_comments";
import ProfilingQuizScreen from "../screens/users/profiling_quiz";

export const UserInfoProviderContext = React.createContext(null);

export const MainLayoutUrl = {
    dashboard: {
        index: {
            path: "/dashboard",
            selectedKey: "dashboard",
            element: <DashboardScreen />,
        },
    },
    banner: {
        index: {
            path: "/banners",
            selectedKey: "banner",
            element: <BannerScreen />
        },
        item: {
            path: "/banners/:id",
            key: 'bannerItem',
            selectedKey: "banner",
            element: <BannerDetailScreen />
        },
    },
    homepage: {
        hotarticle: {
            index: {
                path: "/hotarticle",
                selectedKey: "hotarticle",
                needCache: true,
                element: <HomeArticleScreen />
            },
        },
        hottag: {
            index: {
                path: "/hottag",
                selectedKey: "hottag",
                needCache: true,
                element: <HomeTopicScreen />
            },
        },
    },
    discover: {
        videos: {
            index: {
                path: "/videos",
                selectedKey: "videos",
                needCache: true,
                element: <DiscoverScreen />
            },
            item: {
                path: "/videos/:id",
                key: "videoItem",
                selectedKey: "videos",
                element: <DiscoverDetailScreen />
            },
        },
        hotbounty: {
            index: {
                path: "/hotbounty",
                selectedKey: "hotbounty",
                element: <HotBountyScreen />
            },
        },
        hotpost: {
            index: {
                path: "/hotpost",
                selectedKey: "hotpost",
                element: <DiscoverHotPostsScreen />
            },
        },
        hotvideo: {
            index: {
                path: "/hotvideo",
                selectedKey: "hotvideo",
                element: <DiscoverHotVideosScreen />
            },
        }
    },
    daily: {
        knowledge: {
            index: {
                path: "/knowledge",
                selectedKey: "knowledge",
                element: <DailyScreen />
            },
            item: {
                path: "/knowledge/:id",
                key: "dailyItem",
                selectedKey: "knowledge",
                element: <DailyDetailScreen />
            },
        },
        question: {
            index: {
                path: "/question",
                selectedKey: "question",
                element: <DailyQaScreen />
            },
        },
    },
    course: {
        index: {
            path: "/courses",
            selectedKey: "course",
            element: <CourseScreen />
        },
        item: {
            path: "/courses/:id",
            key: "courseItem",
            selectedKey: "course",
            element: <CourseDetailScreen />
        },
    },
    news: {
        index: {
            path: "/news",
            selectedKey: "news",
            element: <NewsScreen />
        },
        item: {
            path: "/news/:id",
            key: "newsItem",
            selectedKey: "news",
            element: <NewsDetailScreen />
        },
    },
    community: {
        topic: {
            index: {
                path: "/topic",
                selectedKey: "topic",
                element: <TopicScreen />
            },
        },
        userpost: {
            index: {
                path: "/userpost",
                selectedKey: "userpost",
                needCache: true,
                element: <UserPostsScreen />
            }
        },
        userarticle: {
            index: {
                path: "/userarticle",
                selectedKey: "userarticle",
                needCache: true,
                element: <UserArticlesScreen />
            }
        },
        adminpost: {
            index: {
                path: "/adminpost",
                selectedKey: "adminpost",
                needCache: true,
                element: <AdminPostsScreen />
            },
            item: {
                path: "/adminpost/:id",
                key: "postItem",
                selectedKey: "adminpost",
                element: <CommunityDetailScreen />
            },
        },
        adminarticle: {
            index: {
                path: "/adminarticle",
                selectedKey: "adminarticle",
                needCache: true,
                element: <AdminArticlesScreen />
            }
        },
        report: {
            index: {
                path: "/report",
                selectedKey: "report",
                element: <ReportScreen />
            },
        },
        comment: {
            index: {
                path: "/comment",
                selectedKey: "comment",
                needCache: true,
                element: <CommentsScreen />
            },
        },
        tags: {
            index: {
                path: "/alltags",
                selectedKey: "tags",
                needCache: true,
                element: <AllTagsScreen />
            },
            item: {
                path: "/alltags/:id",
                key: 'tagItem',
                selectedKey: "tags",
                element: <TagDetailScreen />
            },
        },
        clabels: {
            index: {
                path: "/clabels",
                selectedKey: "clabels",
                needCache: true,
                element: <ContentTagsScreen />
            },
        },
        crawled: {
            index: {
                path: "/crawled",
                selectedKey: "crawled",
                needCache: true,
                element: <CrawledPostsScreen />
            },
            item: {
                path: "/crawled/:id",
                key: 'crawledItem',
                selectedKey: "crawled",
                element: <CrawledDetailScreen />
            },
        },
        pendingposts: {
            index: {
                path: "/pendingposts",
                selectedKey: "pendingposts",
                element: <PendingPostsScreen />
            },
        },
        pendingarticles: {
            index: {
                path: "/pendingarticles",
                selectedKey: "pendingarticles",
                element: <PendingArticlesScreen />
            },
        },
        pendingcomments: {
            index: {
                path: "/pendingcomments",
                selectedKey: "pendingcomments",
                element: <PendingCommentsScreen />
            },
        }
    },
    users: {
        all: {
            index: {
                path: "/all",
                selectedKey: "all",
                needCache: true,
                element: <UsersScreen />
            },
        },
        recommenduser: {
            index: {
                path: "/recommenduser",
                selectedKey: "recommenduser",
                element: <UserRecommendScreen />
            },
        },
        ulabels: {
            index: {
                path: "/ulabels",
                selectedKey: "ulabels",
                element: <UserLabelScreen />
            },
        },
        virtualuser: {
            index: {
                path: "/virtualuser",
                selectedKey: "virtualuser",
                element: <VirtualUserScreen />
            }
        },
        profilingquiz: {
            index: {
                path: "/profilingquiz",
                selectedKey: "profilingquiz",
                element: <ProfilingQuizScreen />
            }
        }
    },
    points: {
        pointdata: {
            index: {
                path: "/pointdata",
                selectedKey: "pointdata",
                element: <PointDataScreen />
            },
        },
        products: {
            index: {
                path: "/products",
                selectedKey: "products",
                needCache: true,
                element: <PointProductsScreen />
            },
        },
        balance: {
            index: {
                path: "/balance",
                selectedKey: "balance",
                element: <PointBalanceScreen />
            },
        },
        pointrecords: {
            index: {
                path: "/pointrecords",
                selectedKey: "pointrecords",
                needCache: true,
                element: <PointRecordsScreen />
            },
        },
        pointsetting: {
            index: {
                path: "/pointsetting",
                selectedKey: "pointsetting",
                element: <PointSettingScreen />
            },
        },
    },
    crypto: {
        wallet: {
            index: {
                path: "/wallet",
                selectedKey: "wallet",
                element: <WalletScreen />
            },
        },
        transactions: {
            index: {
                path: "/transactions",
                selectedKey: "transactions",
                element: <TransactionsScreen />
            },
        }
    },
    systemconfig: {
        config: {
            index: {
                path: "/configsetting",
                selectedKey: "configsetting",
                element: <SystemSettingScreen />
            },
        },
        admins: {
            index: {
                path: "/admins",
                selectedKey: "admins",
                element: <AdminsScreen />
            },
        },
        roles: {
            index: {
                path: "/roles",
                selectedKey: "roles",
                element: <RolesScreen />
            },
            item: {
                path: "/roles/:id",
                key: "roleItem",
                selectedKey: "roles",
                element: <RoleDetailScreen />
            }
        }
    }
};

function getItem(label, key, icon, children, type) {
    let title = label;
    return {
        key,
        icon,
        children,
        label,
        title,
        type,
    };
}

export const menuItems = [
    getItem('Dashboard', 'dashboard', <DashboardOutlined />),
    getItem('Banners', 'banner', <AppstoreOutlined />),
    getItem('Homepage', 'homepage', <HomeOutlined />, [
        getItem('Hot Article', 'hotarticle', <SnippetsOutlined />),
        getItem('Hot Topic', 'hottag', <FlagOutlined />),
    ]),
    getItem('Discover', 'discover', <InstagramOutlined />, [
        getItem('Videos', 'videos', <VideoCameraOutlined />),
        getItem('Hot Bounty', 'hotbounty', <TagOutlined />),
        getItem('Hot Post', 'hotpost', <FileTextOutlined />),
        getItem('Hot Video', 'hotvideo', <VideoCameraAddOutlined />),
    ]),
    getItem('Daily', 'daily', <CarryOutOutlined />, [
        getItem('Daily Knowledge', 'knowledge', <HighlightOutlined />),
        getItem('Daily Q&A', 'question', <QuestionCircleOutlined />),
    ]),
    getItem('Course', 'course', <BookOutlined />),
    getItem('Platform News', 'news', <GlobalOutlined />),
    getItem('Community', 'community', <CommentOutlined />, [
        getItem('Topic Tags', 'tags', <HolderOutlined />),
        getItem('Content Tags', 'clabels', <ProfileOutlined />),
        getItem('Approval', 'approvals', <AuditOutlined />, [
            getItem('Posts', 'pendingposts'),
            getItem('Articles', 'pendingarticles'),
            getItem('Comments', 'pendingcomments'),
        ]),
        getItem('Posts', 'post', <FileTextOutlined />, [
            getItem('Admin Posts', 'adminpost'),
            getItem('User Posts', 'userpost'),
        ]),
        getItem('Articles', 'article', <ReadOutlined />, [
            getItem('Admin Articles', 'adminarticle'),
            getItem('User Articles', 'userarticle'),
        ]),
        getItem('Bounty Topic', 'topic', <TagsOutlined />),
        getItem('Comment', 'comment', <MessageOutlined />),
        getItem('Report Case', 'report', <IssuesCloseOutlined />),
        getItem('Crawled Posts', 'crawled', <BugOutlined />),
    ]),
    getItem('Users', 'users', <TeamOutlined />, [
        getItem('All', 'all', <UsergroupAddOutlined />),
        getItem('Recommended', 'recommenduser', <UserSwitchOutlined />),
        getItem('Virtual User', 'virtualuser', <IdcardOutlined />),
        getItem('User Labels', 'ulabels', <SolutionOutlined />),
        getItem('Profiling Quiz', 'profilingquiz', <QuestionOutlined />),
    ]),
    getItem('Task Center', 'points', <StarFilled />, [
        getItem('Point Overview', 'pointdata', <LineChartOutlined />),
        getItem('Products', 'products', <ProductOutlined />),
        getItem('Point Balance', 'balance', <TrademarkCircleOutlined />),
        getItem('Point Records', 'pointrecords', <ReconciliationOutlined />),
        getItem('Point Setting', 'pointsetting', <SettingOutlined />),
    ]),
    getItem('Crypto', 'crypto', <DollarOutlined />, [
        getItem('Wallet', 'wallet', <WalletOutlined />),
        getItem('Transactions', 'transactions', <BarsOutlined />),
    ]),
    getItem('System', 'system', <ControlOutlined />, [
        getItem('Admins', 'admins', <UsergroupAddOutlined />),
        getItem('Roles', 'roles', <ClusterOutlined />),
        getItem('Config Setting', 'configsetting', <SettingOutlined />),
    ]),
];

export const getRoutes = () => {
    let routers = Object.keys(MainLayoutUrl).map(key1 => {
        if (Object.keys(MainLayoutUrl[key1]).includes('index')) {
            return Object.keys(MainLayoutUrl[key1]).map(key2 => {
                return {
                    path: MainLayoutUrl[key1][key2].path,
                    key: MainLayoutUrl[key1][key2].key ? MainLayoutUrl[key1][key2].key : MainLayoutUrl[key1][key2].selectedKey,
                    selectedKey: MainLayoutUrl[key1][key2].selectedKey,
                    exact: true,
                    element: MainLayoutUrl[key1][key2].element,
                    needCache: MainLayoutUrl[key1][key2].needCache ? MainLayoutUrl[key1][key2].needCache : false,
                }
            })
        }
        return Object.keys(MainLayoutUrl[key1]).map(key2 => {
            return Object.keys(MainLayoutUrl[key1][key2]).map(key3 => {
                return {
                    path: MainLayoutUrl[key1][key2][key3].path,
                    key: MainLayoutUrl[key1][key2][key3].key ? MainLayoutUrl[key1][key2][key3].key : MainLayoutUrl[key1][key2][key3].selectedKey,
                    selectedKey: MainLayoutUrl[key1][key2][key3].selectedKey,
                    exact: true,
                    element: MainLayoutUrl[key1][key2][key3].element,
                    needCache: MainLayoutUrl[key1][key2][key3].needCache ? MainLayoutUrl[key1][key2][key3].needCache : false,
                }
            });
        }).reduce((x, y) => [...(x ? x : []), ...y]);
    }).reduce((x, y) => [...(x ? x : []), ...y]);
    return routers;
}

const getMenus = (permissionMenus, checkedMenus) => {
    let data = checkedMenus.filter(item => {
        if (permissionMenus.includes(item.key)) return true;
        if (item.children && item.children.length > 0) {
            return Array.from(item.children).some(ch1 => {
                if (permissionMenus.includes(ch1.key)) return true;
                if (ch1.children && ch1.children.length > 0) {
                    return Array.from(ch1.children).some(ch2 => permissionMenus.includes(ch2.key));
                }
                return false;
            })
        }
        return false;
    }).map(item => {
        if (item.children && item.children.length > 0) {
            return { ...item, children: getMenus(permissionMenus, item.children) }
        }
        return item;
    })
    return data;
}

const SystemRouters = () => {
    const [loaded, setLoaded] = useState(false);
    const [menusData, setMenusData] = useState([]);
    const [routesData, setRoutesData] = useState([]);
    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        if (getCookie('user-token')) {
            fetchAdminInfo().then(res => {
                if (res) {
                    setUserInfo(res);
                    let menus = res?.permission?.menus.filter(item => !item.includes('/'));
                    let allRoutes = getRoutes();
                    if (menus?.includes('*')) {
                        setMenusData(menuItems);
                        setRoutesData(allRoutes)
                    } else {
                        let data = getMenus(menus, menuItems);
                        setMenusData(data);
                        setRoutesData(allRoutes.filter(item => {
                            return menus.includes(item.selectedKey)
                        }));
                    }
                    setLoaded(true);
                }
            })
        } else {
            setLoaded(true);
        }
    }, [])

    if (!loaded) {
        return (
            <Flex justify="center" align="center" style={{ height: '60vh' }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} />} />
            </Flex>
        )
    }

    let firstMenuRoute = routesData?.find(item => !item.path?.includes(':'))?.path;
    let notAccess = getCookie('user-token') && !firstMenuRoute ? true : false;

    return (
        <UserInfoProviderContext.Provider value={userInfo}>
            <HashRouter>
                <Routes>
                    <Route
                        path="/login"
                        exact={true}
                        element={
                            <LoginRoute>
                                <LoginPanel />
                            </LoginRoute>
                        }
                    />
                    <Route
                        path="/notaccess"
                        exact={true}
                        element={<NotAccessScreen />}
                    />
                    <Route path='/' element={<MainLayout menus={menusData} accountName={userInfo?.username} />}>
                        <Route path='/' element={<BasicLayoutWithCache />}>
                            {
                                routesData.map((route) => {
                                    return (
                                        <Route
                                            key={route.key}
                                            path={route.path}
                                            exact={route.exact}
                                            element={<PrivateRoute>{route.element}</PrivateRoute>}
                                        />
                                    )
                                })
                            }
                        </Route>
                    </Route>
                    <Route
                        path="*"
                        element={<Navigate to={firstMenuRoute ? firstMenuRoute : (notAccess ? '/notaccess' : '/login')} replace={true} />}
                    />
                </Routes>
            </HashRouter>
        </UserInfoProviderContext.Provider>
    );
};

const LoginRoute = (props) => {
    const { children } = props;
    const isLoggedIn = getCookie("user-token") ? true : false;
    if (!isLoggedIn) {
        return <>{children}</>;
    }
    return <Navigate replace={true} to="/" />;
};

const PrivateRoute = (props) => {
    const { children } = props;
    const isLoggedIn = getCookie("user-token") ? true : false;
    const location = useLocation();
    if (isLoggedIn) {
        return <>{children}</>;
    }
    return (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}${location.search}` }}
        />
    );
};

const BasicLayoutWithCache = () => {
    const outlet = useOutlet();
    const location = useLocation();

    const currRouter = useMemo(() => {
        let _router = getRoutes().find(item => {
            if (location.pathname.indexOf('/') !== location.pathname.lastIndexOf('/') && item.path.indexOf('/') !== item.path.lastIndexOf('/')) {
                return location.pathname.split('/')[1] === item.path.split('/')[1]
            }
            return item.path === location.pathname;
        });
        return _router
    }, [location]);

    if (!currRouter) {
        return <Navigate to={MainLayoutUrl.dashboard.index.path} replace={true} />;
    }

    return <div>
        <KeepAlive activeName={currRouter?.key} max={10} strategy={'LRU'} cache={currRouter?.needCache ?? false}>
            {outlet}
        </KeepAlive>
    </div>
}

export default SystemRouters;
