import { Drawer, Button } from "antd";
import { useEffect } from "react";

const CrawledReview = ({ post, open, onClose }) => {

    useEffect(() => {
        if (post) {
            let ele = document.getElementById(`crawled_cnt_${post.id}`);
            if (ele) {
                ele.innerHTML = '';
                let content = document.createElement('div');
                content.innerHTML = post.content;
                let scripts = content.querySelectorAll('script');
                scripts.forEach((ele) => ele.remove());
                ele.appendChild(content);
            }
        }
    }, [post])

    if (!post) return <div />;

    return (
        <div>
            <Drawer
                key={post.id}
                forceRender={true}
                title={'Crawled Review'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                extra={
                    <Button onClick={onClose}>Close</Button>
                }>
                <div id={`scroll_${post.id}`} style={{ height: '100%', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
                    <div className="post-content-review" id={`crawled_cnt_${post.id}`} />
                </div>
            </Drawer>
        </div>
    )
}

export default CrawledReview;