import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { approvePostOrComment, fetchPostApproveList } from "../../api/community";
import { Button, Pagination, Table, Flex, Select, Space, Input, Tag, message, Image } from "antd";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import { RichText } from "@chatui/core";
import '../../style/community.css';
import PostReview from "../../components/community/post_review";
import UserInfoView from "../../components/community/user_info_view";

const PostApprovePageView = ({ isPost }) => {
    const location = useLocation();
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Post Id',
            dataIndex: 'postId',
            key: 'postId',
            render: (_, record) => {
                const { id } = record.post;
                return <div style={{ fontSize: 12 }}>{id}</div>
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => {
                const { title } = record.post;
                if (!title) return '-';
                return <div style={{ width: 100 }}><b>{title}</b></div>
            },
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return <UserInfoView user={record.post?.user} showWallet={false} />
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (text === 'ApprovePending') {
                    return <Tag color="blue" style={{ fontSize: 12 }}>{text}</Tag>;
                } else if (text === 'ApproveApproved') {
                    return <Tag color="green" style={{ fontSize: 12 }}>{text}</Tag>;
                } else if (text === 'ApproveReject') {
                    return <Tag color="red" style={{ fontSize: 12 }}>{text}</Tag>;
                }
            },
        },
        {
            title: 'Updated Images',
            dataIndex: 'resources',
            key: 'resources',
            render: (text, _) => {
                return (
                    <Space wrap>
                        {
                            text.map((item, index) => {
                                return <Image key={index} src={item.url} style={{ maxWidth: 120, height: 'auto' }} />
                            })
                        }
                    </Space>
                )
            },
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (_, record) => {
                const { content, title } = record.post;
                return (
                    <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, width: 160 }}>
                        <div className="community-content">
                            <RichText content={content ? content : title} />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (_, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { reviewHandle(record) }}>Review</Button>
                        {
                            record.isLatest && record.status === 'ApprovePending' && (
                                <>
                                    <Button type='link' onClick={() => { postHandle(record, 1) }}>Approve</Button>
                                    <Button danger type='link' onClick={() => { postHandle(record, 2) }}>Reject</Button>
                                </>
                            )
                        }
                    </Button.Group>
                )
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        postCategory: isPost ? 'post' : 'article',
        status: '',
        userId: ''
        // keyword: '',
        // gteCreatedAt: '',
        // ltCreatedAt: '',
        // order: '',
    });
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currPost, setCurrPost] = useState();
    const [selectedRows, setSelectedRows] = useState();

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);
    }, [location])

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchPostApproveList(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.gteCreatedAt = `${value[0]}T00:00:00Z`;
                    newParams.ltCreatedAt = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.gteCreatedAt = '';
                    newParams.ltCreatedAt = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const postHandle = (record, status) => {
        setLoading(true);
        let params = { list: [{ id: record.id, status: status }] };
        approvePostOrComment(params).then(res => {
            if (res) {
                fetchData(pageNum, pageSize, searchParams);
                setSelectedRows([])
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    const reviewHandle = (record) => {
        setCurrPost(record.post);
        setOpen(true);
    }

    const approvalBatch = (status) => {
        setLoading(true);
        let params = {
            list: selectedRows.map(item => {
                return { id: item.id, status: status }
            })
        };
        approvePostOrComment(params).then(res => {
            if (res) {
                fetchData(pageNum, pageSize, searchParams);
                setSelectedRows([])
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify="space-between">
                    <Space>
                        {
                            (selectedRows && selectedRows.length > 0) && (
                                <>
                                    <Button onClick={() => approvalBatch(1)} type="primary">Batch Approve</Button>
                                    <Button onClick={() => approvalBatch(2)} danger type="primary">Batch Reject</Button>
                                </>
                            )
                        }
                        <div />
                    </Space>

                    <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 1, label: 'Pending', value: '0' },
                                { key: 2, label: 'Approved', value: '1' },
                                { key: 3, label: 'Rejected', value: '2' },
                            ]}
                            placeholder='Approve status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        <Input
                            placeholder="username"
                            allowClear
                            onChange={evt => onSearchChange('userId', evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false}
                    rowSelection={{
                        selectedRowKeys: (selectedRows ?? []).map(item => item.id),
                        getCheckboxProps: (record) => ({
                            disabled: (record.status !== 'ApprovePending' || !record.isLatest),
                        }),
                        onChange: (keys, rows) => {
                            setSelectedRows(rows);
                        }
                    }} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <PostReview
                from="community"
                open={open}
                post={currPost}
                onClose={() => setOpen(false)}
                onUpdate={() => fetchData(pageNum, pageSize, searchParams)}
            />
        </div>
    )
}

export default PostApprovePageView;